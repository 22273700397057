import { Logo } from "assets"
import { useEffect, useState } from "react"
import useAuthStore from "stores/auth"
import { useNavigate, useLocation } from "react-router-dom"
import { useMutation } from "@apollo/client"
import { generateTokenFromHashMutation } from "graphql/mutations/generateTokenFromHash"
import { getHash } from "utils"
import { reportToSentry } from "utils/reportToSentry"
import RingLoader from "components/RingLoader"

const Header = () => {

  const hash = getHash()

  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const { getCurrentToken, setCurrentToken } = useAuthStore()

  const [ loading, setLoading ] = useState( hash )
  const [ displayLogout, setDisplayLogout ] = useState( false )

  const notOnMyAccountPage = pathname !== `/my-account`
  const onLoginPage = pathname === `/login`

  const [ getTokenFromHash, { error: tokenError }] = useMutation( generateTokenFromHashMutation, {
    variables: {
      sgh: hash
    },
    onCompleted: ( data ) => {
      if ( data?.generateInstantLoginHashToken && data?.generateInstantLoginHashToken?.length ) {
        setCurrentToken( data.generateInstantLoginHashToken[0].token )
      }
      setLoading( false )
    },
    onError: ( error ) => {
      reportToSentry( new Error( `Lactation Link: Active Orders Check Failed`, {
        cause: error
      }) )
      setLoading( false )
    }
  })

  useEffect( () => {
    if ( hash ) getTokenFromHash()
    else setLoading( false )
  }, [] )

  useEffect( () => {
    const hash = getHash()
    const token = getCurrentToken()
    setDisplayLogout( hash || token )
  }, [ pathname, search ] )

  const navigateTo = () => {
    const token = getCurrentToken()

    if ( onLoginPage ) return window.location.href = process.env.REACT_APP_MAGENTO_2_BASE_URL

    return notOnMyAccountPage || tokenError ?
      navigate( `/my-account${window.location.search}` ) :
      window.location.href = token ? `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/customer/order/history?token=${token}` : process.env.REACT_APP_MAGENTO_2_BASE_URL
  }

  // hide the header if the app is running inside an iframe
  if ( window.location !== window?.parent?.location ) return null

  if ( loading ) return <div className="px-8 py-3 my-4"><RingLoader /></div>

  return (
    <div className="px-8 py-3 my-4 relative flex flex-col justify-center items-center gap-3 w-full">
      <img
        className="cursor-pointer md:mx-auto"
        src={Logo}
        alt="logo"
        width={300}
        onClick={navigateTo}
      />
      {
        displayLogout && (
          <a
            className="md:absolute right-3 md:right-8 top-5"
            onClick={() => {
              navigate( `/logout` )
            }}
          >
            {`Logout`}
          </a>
        )
      }
    </div>
  )
}

export default Header
