import React, { useState, useEffect } from 'react'
import { StyledDayPicker, AppointmentButton } from './styled'
import { MONTHS, WEEKDAYS_SHORT } from 'utils/constants'
import 'react-day-picker/lib/style.css'
import MonthSelector from './MonthSelector'
import { ThemedTimezoneSelector } from 'components/ThemedTimezoneSelector'
import AnimatedHeightDiv from 'components/AnimatedHeightDiv/AnimatedHeightDiv'
import { FormattedAppointments } from 'types/shopScheduleTypes'
import useSelectedClasses from 'hooks/useSelectedClasses'
import useDatePicker, { FormattedTimeSlot } from './useDatePicker'
import { dateFromKey, getFirstApptDate } from './utils'

// appointments need to be formatted to have the date string as a key and all of the appointment slots under that key
// key example: 2022-11-21

type DatePickerProps = {
  timeZone: string | null;
  setTimeZone?: React.Dispatch<React.SetStateAction<string>>;
  appointments: FormattedAppointments;
  startTime: string | null | undefined;
  handleTimeClick: ( _time: { start_time: string }) => void;
  onMonthChange: ( _m: Date ) => void;
  error?: string;
  dynamicMonthBoundary?: boolean;
  customTimzoneSelector?: boolean;
}

const DatePicker = ({
  timeZone,
  setTimeZone,
  appointments,
  startTime,
  handleTimeClick,
  onMonthChange,
  error,
  customTimzoneSelector,
  // the below prop will not allow the user to scroll before the earliest appointment date or after the last appoinment date
  // if this prop is not passed it will default to allow the user to navigate to 6 months out
  dynamicMonthBoundary = true
} : DatePickerProps ) : JSX.Element => {

  const { setSavedTimeSelection, savedTimeSelection, clearSavedTimeSelection, dayFromCal, setDayFromCal, clearDayFromCal } = useSelectedClasses()

  const [ selectedMonth, setSelectedMonth ]= useState<Date>( new Date() )

  const { appointmentDates, fromMonth, toMonth, times, selectedDayLabel, sortedAppointmentKeys } = useDatePicker( appointments, dayFromCal, setDayFromCal, setSelectedMonth, dynamicMonthBoundary )

  // this use effect handles saving the users selection if they change their timezone
  useEffect( () => {
    if ( savedTimeSelection ) {
      let newStartTime:FormattedTimeSlot | undefined = undefined
      Object.keys( appointments ).every( apptKey => {
        newStartTime = appointments[ apptKey ]?.find( appt => {
          return appt.event_instance_id === savedTimeSelection
        })
        if ( newStartTime ) return false

        return true
      })
      if ( newStartTime ) {
        const savedStartTimeDate = appointmentDates.find( apptDate => {
          if ( !newStartTime?.start_time ) return false
          const newStartDate = dateFromKey( newStartTime.start_time.slice( 0, 10 ) )

          return apptDate.getMonth() === newStartDate.getMonth() && apptDate.getDate() === newStartDate.getDate() && apptDate.getFullYear() === newStartDate.getFullYear()
        })

        if ( savedStartTimeDate ) {
          setSelectedMonth( savedStartTimeDate )
          setDayFromCal( savedStartTimeDate )
          handleTimeClick({
            start_time: ( newStartTime as FormattedTimeSlot )?.start_time
          })
        }
      }
    }
  }, [ timeZone, appointments ] )



  const handleMonthChangeReset = ( m: Date ) => {
    clearDayFromCal()
    clearSavedTimeSelection()
    setSelectedMonth( m )

    return handleChangeMonth( m )
  }

  const handleMonthSelection = ( e: string ) => {
    const selectedMonthIndex = MONTHS.findIndex( month => { return month === e })
    // if the selected month index is less than the from month index need to go to next calendar year
    const goToDate = new Date( fromMonth.getMonth() > selectedMonthIndex ? toMonth.getFullYear() : fromMonth.getFullYear(), selectedMonthIndex )
    clearDayFromCal()
    setSelectedMonth( goToDate )
    handleChangeMonth( goToDate )
  }

  const handleChangeMonth = ( m : Date ) => {
    setDayFromCal( getFirstApptDate( m, appointments, sortedAppointmentKeys ) )
    setSelectedMonth( m )
    onMonthChange( m )
  }

  return (
    <div>
      <div className="flex justify-center min-h-400 relative">
        <MonthSelector
          fromMonth={fromMonth}
          toMonth={toMonth}
          selectedMonth={selectedMonth}
          handleMonthSelection={handleMonthSelection}
        />
        <StyledDayPicker
          onDayClick={( day : Date ) => { return setDayFromCal( day ) }}
          month={selectedMonth}
          selectedDays={appointmentDates}
          weekdaysShort={WEEKDAYS_SHORT}
          onMonthChange={handleMonthChangeReset}
          fromMonth={fromMonth}
          toMonth={toMonth}
          renderDay={( day : Date ) => {
            return (
              <div className="date-number-wrapper">
                <div className="date-number">{day.getDate()}</div>
              </div>
            )
          }}
          modifiers={{
            birthday: dayFromCal
          }}
        />
      </div>

      <AnimatedHeightDiv
        display
        heightDependencies={[ dayFromCal, times, error ]}
        padHeight={40}
      >
        <div>
          {
            dayFromCal && !customTimzoneSelector && setTimeZone &&
            <ThemedTimezoneSelector timezone={timeZone ?? `US/Eastern`} setTimezone={setTimeZone} />
          }

          {selectedDayLabel && times.length > 0 && (
            <div className="text-center text-2xl mt-10 mb-5">
              {selectedDayLabel}
            </div>
          )}

          <div className="w-full my-0 mx-auto px-2 grid grid-cols-3 gap-3 justify-center items-center mb-10 overflow-hidden">
            {times &&
              times.map( ( time, i ) => {
                return (
                  <AppointmentButton
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    active={time.start_time === startTime}
                    onClick={() => {
                      setSavedTimeSelection( time.event_instance_id )

                      return handleTimeClick( time )
                    }}
                  >
                    <span className={`font-semibold whitespace-nowrap text-sm md:text-base`}>
                      {time.begin_time_pretty}
                    </span>
                    <br />
                    <span className={`lowercase duration-text whitespace-nowrap`}>
                      {`${time.duration_minutes} min${time.duration_minutes > 1 ? `s` : ``}`}
                    </span>
                  </AppointmentButton>
                )
              })}
          </div>
          {error && (
            <div className="text-error text-center text-xs">{error}</div>
          )}
        </div>

      </AnimatedHeightDiv>
    </div>
  )
}

export default DatePicker
