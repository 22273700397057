import useApolloClient from 'apollo/apolloClient'
import { getProductsQueryBySkuArray } from 'graphql/queries/products'
import useActiveTags from 'hooks/useActiveTag'
import { useState, useEffect } from 'react'
import useAuthStore from 'stores/auth'
import { MagentoProduct } from 'types'
import { Class } from 'types/shopScheduleTypes'
import { reportToSentry } from 'utils/reportToSentry'
import { fetchClasses } from '../utils/utils'
import { getHash } from 'utils'

function useFetchClasses() : { classes: Class[] | null, error: string } {

  const [ classes, setClasses ] = useState<Class[] | null>( null )
  const [ filteredClasses, setFilteredClasses ] = useState<Class[] | null>( null )
  const [ error, setError ] = useState<string>( `` )

  const { buildAuthorizer } = useAuthStore()
  const authHeader = buildAuthorizer()
  const apolloClient = useApolloClient()

  const defaultErrorMessage = `Oops... We encountered an error getting the available classes for you.`

  const preRegTestHashes = [
    `C41CC0DD-2F83-40E3-822C-C0B25046E6D7`,
    `C0347D9A-8BB8-43DA-8698-354D14380C06`,
    `3A062B7D-921C-4D5C-9866-13EDC84B69F6`,
    `C4F5CEA9-7888-4297-BC81-D685DDE4E001`,
    `D5784ABF-C0D0-43BD-83D3-8D23706B1515`,
    `C4FAD38D-6DDA-4799-B8AF-F46C07AAB34A`,
    `984A1736-1C3B-4CF0-B2DD-99CA2D4973D3`,
    `D169A7D6-1F60-4F1C-A157-43907FDE767C`,
    `A3546462-AEE3-407C-9405-5916AD8F21CB`,
    `57E19A80-D532-4AB3-82C4-206EBE032783`,
    `50439438-15EC-4AB6-8859-338A417D0F4F`,
    `85A05787-336B-43AA-B704-2E9A956FE2BA`,
    `B2A1724C-2429-44F5-A2CC-07F17F2CB686`
  ]

  const excludeClasses = [
    `yy6z2y6xuinostbl`,
    `vhanzheied370h78`,
    `831nz2czld57wfgm`
  ]

  useEffect( () => {
    if ( authHeader && !classes ) {
      fetchClasses( authHeader )
        .then( data => {
          if ( !data?.data?.classes?.length ) {
            reportToSentry( new Error( `Shop and Schedule: User not eligible for classes` ), {
              authHeader: JSON.stringify( authHeader )
            })

            return setError( `No classes available. You may not be eligible for classes at this time.` )
          }
          if ( data?.data?.classes && !data?.errors?.length && data?.meta?.status === `OK` ) {
            // if on prod filter out test class for pre reg testing
            const filteredClasses = data.data.classes.filter( ( classItem: Class ) => {
              return !excludeClasses.includes( classItem.class_id ) || preRegTestHashes.includes( getHash() )
            })

            return getClassProducts( filteredClasses )
          }
          reportToSentry( new Error( `Shop and Schedule: Error getting classes` ), {
            authHeader: JSON.stringify( authHeader )
          })

          return setError( defaultErrorMessage )
        })
        .catch( ( error: Error ) => {
          reportToSentry( new Error( `Shop and Schedule: Error getting classes`, {
            cause: error
          }), {
            authHeader: JSON.stringify( authHeader )
          })

          return setError( defaultErrorMessage )
        })
    }

    return () => { filterClassesSubscription() }

  }, [ authHeader ] )

  const filterClassesSubscription = useActiveTags.subscribe( ( state ) => {
    setFilteredClasses( classes ? classes.filter( ( classDetails ) => {
      if ( !state?.activeTag ) return true
      if ( classDetails?.category && state.activeTag === classDetails?.category ) return true

      return false
    }) : [] )
  })

  const getClassProducts = async ( classData: Class[] ) => {

    const classSkusArray:string[] = classData.filter( ( classItem: Class ) => { return Boolean( classItem.class_sku ) }).map( ( classItem: Class ) => { return classItem.class_sku })

    const classProductsResult = await apolloClient.query({
      query: getProductsQueryBySkuArray,
      fetchPolicy: `network-only`,
      variables: {
        skus: classSkusArray,
        pageSize: classSkusArray.length
      }
    })
      .catch( ( error: Error ) => {
        reportToSentry( new Error( `Shop and Schedule: Product query error`, {
          cause: error
        }), {
          classSkusArray: JSON.stringify( classSkusArray )
        })

        return setError( defaultErrorMessage )
      })

    if ( !classProductsResult?.data?.resupplyProducts?.items ) {
      reportToSentry( new Error( `Shop and Schedule: Product query error` ), {
        classSkusArray: JSON.stringify( classSkusArray )
      })

      return setError( defaultErrorMessage )
    }

    const updateClassData = classData.map( ( classItem: Class ) => {
      const magentoClassDetails:MagentoProduct = classProductsResult.data.resupplyProducts.items.find( ( resultClass: MagentoProduct ) => {
        return classItem.class_sku === resultClass.sku
      })
      if ( magentoClassDetails ) {
        return {
          ...classItem,
          small_image_path: magentoClassDetails?.small_image?.url ?? ``,
          image_path: magentoClassDetails?.image?.url ?? ``,
          internal_class_title: classItem.class_title,
          class_title: magentoClassDetails?.name,
          class_description: magentoClassDetails?.short_description?.html ?? ``,
          category: magentoClassDetails.lactation_course_filters
        }
      } else {
        return {
          ...classItem,
          internal_class_title: classItem.class_title
        }
      }


    }) as Class[]

    setClasses( [ ...updateClassData ] )

    return setFilteredClasses( [ ...updateClassData ] )
  }

  return {
    classes: filteredClasses,
    error
  }
}

export default useFetchClasses